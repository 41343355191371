<template>
  <div class="wrapper">
    <div class="logo-box">
      <template v-if="codeType && codeType == '200' || codeType == 200">
        <img src="@/assets/successful.png" alt="" />
        <p>开票成功</p>
      </template>
      <template v-else>
        <img src="@/assets/error.png" alt="">
        <p>开票失败</p>
      </template>
    </div>
    <div class="btn-box">
      <p class="back-btn">查看发票</p>
      <p class="cancel-btn" @click="cancel">返回我的保单</p>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      codeType:'',
    };
  },
  mounted() {
    this.codeType = this.$route.query.code;
  },
  methods: {
    cancel() {
      this.$router.push({
        path: "/orderList",
      });
    },
  },
};
</script>

<style scoped lang='scss'>
.wrapper {
  padding: 0 15px;
  overflow: hidden;
  .logo-box {
    width: 134px;
    height: 94px;
    margin: 0 auto;
    // margin: 100px 139px 43px 0px;
    margin-top: 70px;
    img {
      width: 67px;
      height: 47px;
      margin: 0 auto;
    }
    p {
      font-size: 17px;
      color: #666666;
      margin-top: 10px;
      height: 24px;
      line-height: 24px;
      text-align: center;
    }
  }
  .btn-box {
    margin-top: 132px;
    .back-btn {
      margin: 0 auto;
      //   margin-top: 73px;
      width: 325px;
      height: 45px;
      line-height: 45px;
      background: #ff7418;
      border-radius: 8px;
      text-align: center;
      color: #ffffff;
      font-weight: bold;
      font-size: 16px;
    }
    .cancel-btn {
      margin: 0 auto;
      margin-top: 15px;
      width: 325px;
      height: 45px;
      line-height: 45px;
      border-radius: 8px;
      text-align: center;
      font-weight: bold;
      font-size: 16px;
      color: #ff7418;
      border: 1px solid #ff7418;
    }
  }
}
</style>